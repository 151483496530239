<template>
   <ObiText
      v-bind="attrs"
      v-on="$listeners"
      :class="['obi-icon', iconStacked && `obi-icon--stacked bg-${iconStacked}`]"
   >
      <ObiText
         as="i"
         :style="{ fontSize: height }"
         v-if="typeof icon === 'string' && !endsWith(icon, '.svg')"
         :class="[icon, color && `text-${color}`]"
      />
      <ObiImage
         :src="icon"
         :width="width"
         :height="height"
         v-else-if="typeof icon === 'string' && endsWith(icon, '.svg')"
      />
      <component
         :is="icon"
         :width="width"
         :height="height"
         v-else-if="typeof icon !== 'string'"
         :class="[icon, color && `stroke-${color}`]"
      />
   </ObiText>
</template>

<script>
import { endsWith, keys, omit } from 'lodash';

export default {
   name: 'ObiIcon',
   props: {
      icon: {
         type: [String, Object],
         default: null,
      },
      iconStacked: {
         type: Boolean,
      },
      width: {
         type: [String, Number],
         default: null,
      },
      height: {
         type: [String, Number],
         default: null,
      },
      color: {
         type: String,
         default: null,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   methods: {
      endsWith,
   },
};
</script>
<style lang="scss" scoped>
.obi-icon {
   line-height: 1;

   i {
      line-height: 1;
   }

   &.obi-icon--stacked {
      width: 35px;
      height: 35px;
      display: flex;
      background: #2f71f2;
      align-items: center;
      border-radius: 0.5rem;
      justify-content: center;

      svg {
         width: auto;
         height: 74%;
         stroke: #fff !important;
      }
   }
}
</style>
